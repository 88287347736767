import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  AccessibilityLabelPartial,
  TestIdPartial,
  SpacePartial,
  IsReadOnlyPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-textarea.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="forms/textarea"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="flexHeight" types={['boolean']}>
            <Text>
              Determine whether the height of the textarea uses a{' '}
              <code>flex</code> value. If <code>true</code>, the component
              should be used within a parent container that contains{' '}
              <code>display: 'flex'</code> style.
            </Text>
            <Text>
              <code>false</code> (default)
            </Text>
          </PropListItem>

          <PropListItem name="hasError" types={['boolean']}>
            <Text>
              Determine whether to show an error state (red text and border) on
              the textarea.
            </Text>
          </PropListItem>

          <PropListItem name="helpText" types={['string']}>
            <Text>
              The text that appears below the textarea, usually to guide the
              user on what to provide.
            </Text>
          </PropListItem>

          <PropListItem name="isDisabled" types={['boolean']}>
            <Text>Determines whether the textarea is disabled.</Text>
          </PropListItem>

          <IsReadOnlyPartial componentName="text area" />

          <PropListItem name="isRequired" types={['boolean']}>
            <Text>
              Determines whether the required text appears next to the label.
            </Text>
          </PropListItem>

          <PropListItem name="label" types={['string']}>
            <Text>The text that appears above the textarea.</Text>
          </PropListItem>

          <PropListItem name="minHeight" types={['number']}>
            <Text>Changes the default min-height for the textarea.</Text>
          </PropListItem>

          <PropListItem name="size" types={['string']}>
            <Text>Determines the size of the input.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <SpacePartial />
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="textarea" />

          <TestIdPartial componentName="textarea" />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from a{' '}
              <Link href="https://reactnative.dev/docs/textinput">
                text input
              </Link>{' '}
              component.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            To show an error message, simply pass in <code>hasError</code> and
            put the error text in the <code>helpText</code> attribute.
          </li>
        </List>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Forms / Textarea"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
