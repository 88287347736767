import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  FormSizePartial,
  HasErrorPartial,
  HelpTextPartial,
  IsDisabledPartial,
  IsReadOnlyPartial,
  IsRequiredPartial,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Textarea = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Textarea;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/textarea.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Text Area"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-text-area--docs"
      />
      <CodeSnippet
        scope={{ React: React, Textarea: Textarea }}
        code={snippet}
        platform="react"
        gitHubLink="forms/textarea"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ClassnamePartial componentName="text area" />

          <EnvironmentPartial componentName="text area" />

          <FormSizePartial componentName="text area" />

          <HasErrorPartial componentName="text area" />

          <HelpTextPartial componentName="text area" />

          <IsDisabledPartial componentName="text area" />

          <IsReadOnlyPartial componentName="text area" />

          <IsRequiredPartial componentName="text area" />

          <PropListItem name="label" types={['string']}>
            <Text>The text that appears above the text area.</Text>
          </PropListItem>

          <PropListItem name="minHeight" types={['string']}>
            <Text>Changes the min-height from the default of 200px.</Text>
          </PropListItem>

          <PropListItem name="autoHeight" types={['boolean']}>
            <Text>
              Determines whether the text area dynamically grows/shrinks height
              based on user input.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial
            componentName="text area"
            passedDown={['label', 'help-text']}
          />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from an HTML{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea">
                text area
              </Link>
              .
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            If you pass in a <code>value</code> prop, be sure you also pass in
            an <code>onChange</code> prop so the{' '}
            <Link href="https://reactjs.org/docs/forms.html#controlled-components">
              component will update
            </Link>{' '}
            when the user types.
          </li>
          <li>
            Since the <code>name</code> attribute is passed to the{' '}
            <code>textarea</code>
            ’s <code>id</code>, make sure you provide a unique name.
          </li>
          <li>
            To show an error message, simply pass in <code>hasError</code> and
            put the error text in the <code>helpText</code> attribute.
          </li>
        </List>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Forms / Textarea"
      />
    </PlatformTab>
  );
};

export default WebTab;
